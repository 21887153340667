var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center",
    staticStyle: {
      "min-width": "102px"
    },
    attrs: {
      "id": "search-date-picker"
    }
  }, [_vm._t("icon"), _c('flat-pickr', {
    ref: "flatPickrRef",
    staticClass: "form-control text-body font-weight-bold text-subtitle",
    staticStyle: {
      "min-width": "200px"
    },
    attrs: {
      "id": "flat-pickr-date",
      "value": _vm.dateValue,
      "config": Object.assign({
        dateFormat: 'Y-m-d',
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        shorthandCurrentMonth: true,
        disableMobile: true
      }, _vm.config),
      "events": ['onDayCreate', 'onOpen', 'onMonthChange'],
      "placeholder": "Chọn ngày"
    },
    on: {
      "input": function input(val) {
        return _vm.handleChange(val);
      },
      "on-day-create": _vm.listenToOnDayCreateEvent
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }